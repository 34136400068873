import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCustomTranslation } from '@/i18n';

export const usePageTitle = () => {
  const location = useLocation();
  const { t } = useCustomTranslation();
  useEffect(() => {
    if (location.pathname === '/registration') {
      document.title = t('ws-registration-page-main-title');
    } else if (location.pathname === '/login') {
      document.title = t('ws-login-page-main-title');
    } else if (location.pathname === '/disconnect') {
      document.title = t('ws-disconnect-page-main-title');
    } else {
      document.title = t('ws-name');
    }
  }, [location]);
};
