import { WsErrorCodes, WsErrorTypes } from '@bridge/WsError';
import {
  SessionProtocols,
  IActionResultType,
} from '@/bridge/types/SessionTypes';
import { ActionTypes, ActionClientInputs } from '@bridge/types/SelfService';
import { WsBrokerOperationState } from '@/bridge/types/WorkSpaceStateTypes';
import {
  AuthCapability,
  PlatformType,
} from '@/bridge/types/SoloRTCChannelTypes';
export type {
  IPerformResourceActionRequest,
  IPerformResourceActionResponse,
} from '@bridge/types/SelfService';

/*
   Added request and resource object types for WSBroker API's in single place to avoid having multiple files for these
   and also these types are not used anywhere expect WSBrokerService.ts code and haven't changed for few years now.
   Dont think this will reduce code readability, since these are pretty self explanatory.
*/
/** General */
export interface SessionContext {
  SessionId: string;
  AuthProvider?: AuthProvider;
  AuthToken?: IToken;
}

/** GetAuthInfoTypes */
export enum ClientInfoPlatform {
  Windows = 'Windows',
  OSX = 'OSX',
  Android = 'Android',
  iOS = 'iOS',
  Chrome = 'Chrome',
  Web = 'Web',
  Linux = 'Linux',
}

export enum ClientCapability {
  OAUTH = 'OAUTH',
  SSO_AUTH_PROVIDER = 'SSO_AUTH_PROVIDER',
  SAML_IAM_AUTH_PROVIDER = 'SAML_IAM_AUTH_PROVIDER',
  LOGIN_TYPE_FIXED_AUTHBLOB = 'LOGIN_TYPE_FIXED_AUTHBLOB',
  IDC_AUTH_PROVIDER = 'IDC_AUTH_PROVIDER',
  TOKEN = 'TOKEN',
  DEVICE_VIA_CERTIFICATE = 'DEVICE_VIA_CERTIFICATE',
  VIRTUAL_SMART_CARD_CERTIFICATE = 'VIRTUAL_SMART_CARD_CERTIFICATE',
}

export interface IAuthInfoRequest {
  AuthIdentifiers: {
    WS_REGISTRATION_CODE?: string[];
    WS_CONNECTION_ALIAS?: string[];
  };
  ClientInfo: {
    AuthCapabilities: string[];
    Identifier: string;
    Name: string;
    Platform: string;
    Version: string;
    VendorName?: string;
    ModelNumber?: string;
    DeviceUUID?: string;
    PKCECodeChallenge: string;
  };
  Version: string;
}

export interface GetAuthInfoRequestBodyProps {
  regCode: string;
  isConnectionAlias: boolean;
  clientVersion: string;
  clientName: string;
  platformType: PlatformType;
  authCapabilities: AuthCapability[];
  vendorName?: string;
  modelNumber?: string;
  deviceUUID?: string;
  codeChallenge: string;
}

export enum AuthMethodMethodType {
  USER = 'USER',
  DEVICE = 'DEVICE',
  REAUTHENTICATE = 'REAUTHENTICATE',
}

export enum AuthMethodAuthType {
  OAUTH = 'OAUTH',
  TOKEN = 'TOKEN',
  CERTIFICATE = 'CERTIFICATE',
}

export enum AuthProvider {
  WARP_DRIVE = 'WARP_DRIVE',
  SAML_IAM = 'SAML_IAM',
  IDC = 'IDC',
  SSO = 'SSO',
}

export interface AuthMethod {
  MethodType: AuthMethodMethodType;
  AuthType: AuthMethodAuthType;
  AuthProvider?: AuthProvider;
  Url?: string;
  Nonce?: string;
  Certificates?: string[];
  Token?: string;
  Username?: string;
}

export interface DomainProperties {
  OrganizationName: string;
}

export interface IAuthInfoSuccessResponse {
  AuthMethods: AuthMethod[];
  DomainProperties: DomainProperties;
  SessionContext: {
    AuthProvider: AuthProvider;
    SessionId: string;
  };
}

export interface IWSBrokerServiceErrorResponse {
  code: string;
  message: string;
}

export interface IWSBrokerErrorResponse {
  ErrorCode: WsErrorCodes;
  ErrorType: WsErrorTypes;
}

// Return type for fetchAuthInfo API
export type IAuthInfoResponse =
  | IAuthInfoSuccessResponse
  | IWSBrokerServiceErrorResponse;

/** Authenticate API Types */

export interface AuthMethodStructure {
  MethodType: AuthMethodMethodType;
  AuthType: AuthMethodAuthType;
  Url?: string;
  Token?: string;
  State?: string;
  Username?: string;
  Password?: string;
  AuthProvider?: AuthProvider;
  PKCECodeVerifier?: string;
}

export interface IAuthenticateRequest {
  Version: string;
  SessionContext: SessionContext;
  AuthDetails: AuthMethodStructure;
}

export interface IToken {
  ExpirationTimestamp: number;
  Value: string;
}

export interface IAuthenticateSuccessResponse {
  AuthProviderInfo?: {
    DirectoryId: string;
    DomainName: string;
  };
  AuthToken?: IToken;
  SessionContext: {
    AuthProvider?: AuthProvider;
    AuthToken?: IToken;
    SessionId: string;
  };
  NextAuthMethods?: AuthMethod[];
  FallbackAuthMethod?: AuthMethod;
}

export type IAuthenticateResponse =
  | IAuthenticateSuccessResponse
  | IWSBrokerServiceErrorResponse;

/** GetResource API Types */
export interface IGetResourceRequest {
  Version: string;
  SessionContext: {
    SessionId: string;
    AuthToken: {
      Value: string;
    };
  };
  AuthToken: {
    Value: string;
  };
}

interface IResourceDetails {
  WorkSpaceOperatingSystemType: string;
  WorkspaceCapabilities: string;
  WorkSpaceOperatingSystemName: string;
  RootVolumeSizeInGiB: string;
  CreationTime: string;
  AwsAccountId: string;
  IpAddress: string;
  WorkSpaceName: string;
  RunningMode: string;
  ComputeType: string;
  UserVolumeSizeInGiB: string;
  PendingActions?: string;
}
interface IUserInputValue {
  Type: 'ENUM';
  Values: string[];
  AvailableTime?: string;
}
interface IActionUserInput {
  [key: string]: IUserInputValue;
}
interface IAction {
  ActionType: ActionTypes;
  ActionClientInput?: ActionClientInputs[];
  ActionUserInput?: IActionUserInput;
  AvailableTime?: string;
}

export interface IKeyValuePair {
  Key: string;
  Value: string;
}

export enum IResourceType {
  WORKSPACE = 'WORKSPACE',
  WORKSPACE_POOL = 'WORKSPACE_POOL',
}

export interface IResource {
  ActionsAuthorized: IAction[];
  ResourceDetails: IResourceDetails;
  ResourceId: string;
  ResourceType: IResourceType;
  State: WsBrokerOperationState;
}

export interface IGetResourcesSuccessResponse {
  PollingConfiguration: {
    PollingIntervalSeconds: number;
  };
  Resources: IResource[];
}

export type IGetResourceResponse =
  | IGetResourcesSuccessResponse
  | IWSBrokerErrorResponse;

/** AllocateResource Types */
export interface IAllocateResourceRequest {
  Version: string;
  SessionContext: {
    SessionId: string;
    AuthToken: {
      Value: string;
    };
  };
  AuthToken: {
    Value: string;
  };
  Resource: {
    ResourceType: string;
    ResourceId: string;
  };
  Action: {
    ActionType: string;
    ActionClientInput: {
      ClientIp: string;
      ClientMacAddress: string;
      ClientVersion?: string;
      Timezone: string;
    };
    ActionUserInput: {
      Protocol: string;
    };
  };
  WSPClientMetadata: IKeyValuePair[];
}

export enum LoginTypes {
  NONE = 'NONE',
  USERPASS = 'USERPASS',
  SMARTCARD = 'SMARTCARD',
  SEAMLESS = 'SEAMLESS',
}

export enum WsBrokerLoginType {
  VirtualSmartCardCertificate = 'VIRTUAL_SMART_CARD_CERTIFICATE',
  FixedAuthBlob = 'FIXED_AUTHBLOB',
  Token = 'TOKEN',
}

export enum IpVersion {
  IPv6 = 'IPv6',
  IPv4 = 'IPv4',
}

export interface ExtendedDcvEndpoint {
  transport: EndpointTransports;
  hostname: string;
  gatewayAuthToken: string;
  port: string;
  tcpHostname: string;
  tcpPort: string;
  gatewayType: any;
  ipVersion: IpVersion;
}

export interface IActionResultValues {
  retryTimeSeconds: string;
  retryTimeoutSeconds: string;
  allocationRetryTime: string;
  gatewaysConnectionInformation: any;
  sessionProtocol: SessionProtocols;
  authBlob: any;
  sessionId: string;
  dcvEndpoints: any;
  extendedDcvEndpoints: any;
  destinationIpAddress: string;
  loginType?: WsBrokerLoginType;
}

export enum EndpointTransports {
  TCP = 'TCP',
  QUIC = 'QUIC',
}

export interface IEndPoints {
  transport?: EndpointTransports;
  url: string;
  port: string;
}

export interface IExtendedEndPoint {
  transport?: EndpointTransports;
  hostname: string;
  gatewayAuthToken: string;
  port: string;
  tcpHostname: string;
  tcpPort: string;
  gatewayType: string;
  ipVersion: string;
}

export interface IAllocateResourceSuccessResponse {
  ActionResult: {
    ResultType: IActionResultType;
    ResultValues: IActionResultValues;
  };
}

export interface IGetHeartBeatInfoSuccessResponse {
  SessionContext: SessionContext;
  heartBeatEndpoint: string;
  heartBeatSessionId: string;
  HeartBeatContext: {
    intervalInSeconds: number;
  };
}

/** GetHeartBeatInfo API Types */
export interface IGetHeartBeatInfoRequest {
  SessionId: string;
  AuthToken: {
    Value: string;
  };
}
