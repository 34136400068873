import { WsError } from '@/core/error/WsError';
import { WsErrorTypes } from '@/core/error/WsErrorTypes';
import { WsErrorCodes } from '@/core/error/WsErrorCodes';
import { ILogger } from '@bridge/ILogger';
import { IRegion } from '@/bridge/types/RegionTypes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { IPersistentStorage } from '@/bridge/IPersistentStorage';
import { ISessionManager } from '@/bridge/ISessionManager';
import { useRegCodes } from '@stores/registration';
import { RegionResolver } from '@core/region/RegionResolver';
import { IDevice } from '@bridge/IDevice';
import { IRTCChannel } from '@bridge/IRTCChannel';
import { ClientError } from '@core/error/ClientError';

export class Registration {
  private code: string | undefined;
  private region: IRegion | undefined;
  private isRegistrationStarted: boolean = false;
  private isRegistrationComplete: boolean = false;
  private readonly persistStore: IPersistentStorage;
  private readonly sessionManager: ISessionManager;
  private readonly logger: ILogger;
  private readonly regionResolver: RegionResolver;
  private readonly device: IDevice;
  private readonly rtcchannel: IRTCChannel | null;
  private static readonly regCodeRegExp = '^[A-Z0-9]{6}$';
  constructor(
    logger: ILogger,
    persistStore: IPersistentStorage,
    sessionManager: ISessionManager,
    regionResolver: RegionResolver,
    device: IDevice,
    rtcchannel: IRTCChannel | null
  ) {
    this.logger = logger;
    this.persistStore = persistStore;
    this.sessionManager = sessionManager;
    this.regionResolver = regionResolver;
    this.device = device;
    this.rtcchannel = rtcchannel;
  }

  getIsRegistrationStarted() {
    return this.isRegistrationStarted;
  }

  getIsRegistrationComplete() {
    return this.isRegistrationComplete;
  }

  async register(code: string | undefined) {
    const isNativeMode = this.sessionManager.isNativeMode();

    this.isRegistrationStarted = true;
    this.logger.info(`Starting workspace registration for code:${code}`);
    this.code = this.getRegCode(code);
    this.validateRegCodeFormat();

    // Add regCode immediately to decorate metrics that need to be published
    this.updateRegCodeInSessionStorage(this.code);
    this.region = await this.regionResolver.getRegionFromRegCode(this.code);
    this.validateRegionSupport(this.region);

    // Handle URL redirection if needed
    const expectedClientUrlBasedOnRegCode =
      RegionResolver.getWebClientLoginRedirectEndPointFromRegion(
        this.region as IRegion
      );
    this.logger.info(
      `Identified the expected client url of workspace with code::${this.code} as ${expectedClientUrlBasedOnRegCode}`
    );
    if (this.isRegCodeBasedRedirectionNeeded(expectedClientUrlBasedOnRegCode)) {
      const expectedRedirectionUrlWithPort = this.buildRedirectionUrlWithPort(
        expectedClientUrlBasedOnRegCode,
        this.code
      );
      this.logger.info(
        `Client needs to redirect to ${expectedRedirectionUrlWithPort} before registering workspace`
      );
      this.isRegistrationComplete = false;
      window.location.assign(expectedRedirectionUrlWithPort);
      return;
    }

    this.sessionManager.set({
      region: this.region,
    });
    this.logger.info(
      `Completed Registration for workspace with code::${this.code} and identified region as ${this.region?.endpoint}`
    );

    if (isNativeMode) {
      this.publishSettingsUpdateToSolo();
    }

    this.isRegistrationComplete = true;
  }

  publishSettingsUpdateToSolo() {
    const previousRegistrationCode = this.sessionManager.get(
      'previousRegistrationCode'
    );
    if (previousRegistrationCode !== this.code) {
      this.sessionManager.set({
        previousRegistrationCode: this.code,
      });
      console.info(
        `RTCChannel is open. Sending RegistrationCode update details to RTC listener code: ${this.code}, metric-endpoint: ${this.region?.metricEndpoint}, region-endpoint: ${this.region?.endpoint}`
      );
      // Based on the reg-code the metric end-point & region end-point will be sent to the solo client
      this.rtcchannel?.publishSettingsChange({
        RegistrationCode: this.code,
        MetricEndpoint: this.region?.metricEndpoint,
        RegionEndpoint: this.region?.endpoint,
        WebClientEndpoint: 'https://' + window.location.hostname,
      });
    }
  }

  reset() {
    this.persistStore.set('regCodeLastUsed', '');
    this.sessionManager.set({
      registrationCode: '',
      region: undefined,
    });
    this.isRegistrationStarted = false;
  }

  async getWebClientRegionEndpointByCode(
    code: string | undefined,
    isExcludeSearchParams: boolean,
    path?: string
  ) {
    this.code = this.getRegCode(code);
    this.validateRegCodeFormat();

    this.region = await this.regionResolver.getRegionFromRegCode(this.code);
    this.validateRegionSupport(this.region);
    const expectedWebClientEndpoint =
      RegionResolver.getWebClientLoginRedirectEndPointFromRegion(
        this.region as IRegion
      );
    const webUrl = new URL(expectedWebClientEndpoint);
    webUrl.port = window.location.port;
    if (path) {
      webUrl.pathname = path;
    }
    if (!isExcludeSearchParams) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      urlSearchParams.forEach((value, key) => {
        webUrl.searchParams.set(key, value);
      });
    }
    return webUrl;
  }

  private isRegCodeBasedRedirectionNeeded(
    expectedRedirectionUrl?: string
  ): boolean {
    const currentUrlHostname = window.location.hostname;
    if (
      expectedRedirectionUrl &&
      !expectedRedirectionUrl
        .toLowerCase()
        .includes(currentUrlHostname?.toLowerCase())
    ) {
      return true;
    }
    return false;
  }

  private buildRedirectionUrlWithPort(
    expectedRedirectionUrl: string,
    registrationCode: string
  ): string {
    const currentUrlPort = window.location.port;
    const currentSearchParams = window.location.search;
    const urlSearchParams = new URLSearchParams(currentSearchParams);
    urlSearchParams.set('registrationCode', registrationCode);

    const updatedUrl = new URL(expectedRedirectionUrl);
    updatedUrl.port = currentUrlPort;
    urlSearchParams.forEach((value, key) => {
      updatedUrl.searchParams.append(key, value);
    });

    return updatedUrl.toString();
  }

  // This is to ensure that any UI code watching for regCode, doesnt get updated again by a regCode change
  private updateRegCodeInSessionStorage(code: string) {
    if (this.sessionManager.get('registrationCode') !== code) {
      this.sessionManager.set({ registrationCode: code });
    }
  }

  private getRegCode(code: string | undefined): string {
    const getRegCodeForAlias = useRegCodes.getState().getRegCodeForAlias;
    const regCode = getRegCodeForAlias(code as string);

    if (regCode) {
      return regCode ?? '';
    } else {
      return code as string;
    }
  }

  private validateRegCodeFormat(): void {
    if (!this.code) {
      this.logger.fatal(
        `Error registering. Undefined regCode received - ${this.code}`
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_REGISTRATION,
        WsErrorCodes.ERROR_INVALID_REG_CODE,
        ClientErrorCode.RegCodeFormatInvalid
      );
    }
    if (!RegionResolver.isConnectionAlias(this.code)) {
      this.validateVanillaRegCodeFormat();
    }
  }

  private validateVanillaRegCodeFormat() {
    if (this.code?.includes('+')) {
      const regCodeParts = this.code?.split('+');
      const regExp = new RegExp(Registration.regCodeRegExp);
      if (
        regCodeParts.length !== 2 ||
        !regCodeParts[0]?.trim() ||
        !regExp.test(regCodeParts[1])
      ) {
        this.logger.fatal(
          `Error registering. Undefined regCode received - ${this.code}`
        );
        throw new WsError(
          WsErrorTypes.ERROR_TYPE_REGISTRATION,
          WsErrorCodes.ERROR_INVALID_REG_CODE,
          ClientErrorCode.RegCodeFormatInvalid
        );
      }
    } else {
      this.logger.fatal(
        `Registration error. Registration code format is invalid - ${this.code}`
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_REGISTRATION,
        WsErrorCodes.ERROR_FORMAT,
        ClientErrorCode.RegCodeFormatInvalid
      );
    }
  }

  validateRegionSupport(region?: IRegion) {
    if (!region) {
      this.logger.fatal(
        `Error registering. Unable to determine region for ${this.code}`
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_REGISTRATION,
        WsErrorCodes.ERROR_PREFIX,
        ClientErrorCode.RegCodeRegionNotFound
      );
    }

    if (!RegionResolver.isRegCodeSupportedByCurrentStack(region)) {
      this.logger.fatal(
        `Registration code provided ${this.code}  with region ${region?.code} is no longer supported on current website endpoint.`
      );
      throw new ClientError(ClientErrorCode.RegCodeRegionUnsupportedByStack);
    }
  }
}
