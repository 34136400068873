import {
  Action,
  ActionType,
  AuthContextInputs,
  AuthContextRequest,
  AuthContextRequestType,
  DisconnectRequest,
  EligibleLogFileInfo,
  GetPlatformDetailsRequest,
  HealthCheckRequest,
  LogMessage,
  LogUploadDetails,
  RTCChannelMessage,
  SessionState,
  SettingsUpdateRequest,
  StateChangeUpdate,
  StreamContextRequest,
  StreamRequest,
  UserSettings,
  DNSRecordType,
  CustomizationConfig,
  ExternalAuthRedirectConfig,
  DisplayWarpDriveURL,
  BrokerAuthenticationResult,
} from '@bridge/types/SoloRTCChannelTypes';
import { WsError } from '@core/error/WsError';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { SessionProtocols } from '@bridge/types/SessionTypes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { LogLevel } from '@core/constants/LoggerConstants';

export const SoloNativeRTCUtility = {
  buildRTCChannelMessage: (
    action: Action,
    actionType: ActionType,
    payload: any
  ): RTCChannelMessage => {
    return {
      Version: '1.0',
      Action: action,
      ActionType: actionType,
      Payload: payload,
    };
  },

  buildRTCPlatformDetailsRequest: (userSettings: UserSettings) => {
    const platformDetailsRequest: GetPlatformDetailsRequest = {
      UserSettings: userSettings,
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.GET_PLATFORM_DETAILS,
      ActionType.REQUEST,
      platformDetailsRequest
    );
  },

  buildRTCStateChangeMessage: (
    sessionState: SessionState,
    stateConfig: CustomizationConfig | ExternalAuthRedirectConfig
  ): RTCChannelMessage => {
    const stateChangeUpdate: StateChangeUpdate = {
      State: sessionState,
      StateConfig: stateConfig,
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.STATE_CHANGE,
      ActionType.RESPONSE,
      stateChangeUpdate
    );
  },

  buildRTCStreamRedirectMessage: (
    protocol: SessionProtocols,
    domainName: string,
    allocatedResource: any
  ): RTCChannelMessage => {
    const streamRedirectRequest: StreamContextRequest = {
      Protocol: protocol,
      DomainName: domainName,
      AllocatedResource: allocatedResource,
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.STREAM_CONTEXT,
      ActionType.REQUEST,
      streamRedirectRequest
    );
  },

  buildRTCStreamInitMessage: (
    protocol: SessionProtocols,
    streamInputs: string
  ) => {
    const streamRequest: StreamRequest = {
      Protocol: protocol,
      StreamInputs: streamInputs,
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.STREAM,
      ActionType.REQUEST,
      streamRequest
    );
  },

  buildRTCDisconnectRequestMessage: () => {
    const disconnectError: DisconnectRequest = {
      DisconnectCode: '',
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.DISCONNECT,
      ActionType.REQUEST,
      disconnectError
    );
  },

  buildRTCPublishLogRequestMessage: (message: string, logLevel: LogLevel) => {
    const logPayload: LogMessage = {
      Message: message,
      LogLevel: logLevel,
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.PUBLISH_LOG,
      ActionType.REQUEST,
      logPayload
    );
  },

  buildRTCLogUploadTriggerMessage: () => {
    const logUploadPayload: LogUploadDetails = {
      ClientLogFiles: '',
      ProtocolLogFiles: '',
    };

    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.TRIGGER_LOG_UPLOAD,
      ActionType.REQUEST,
      logUploadPayload
    );
  },

  buildRTCLogUploadPublishMessage: (eligibleLogFileName: string) => {
    const eligibleLogNamePayload: EligibleLogFileInfo = {
      EligibleLogFileName: eligibleLogFileName,
    };

    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.LOG_UPLOAD_PUBLISH,
      ActionType.REQUEST,
      eligibleLogNamePayload
    );
  },

  buildRTCAuthContextRequestMessage: (
    authContextRequestType: AuthContextRequestType,
    authContextInputs: AuthContextInputs
  ) => {
    const authContextPayload: AuthContextRequest = {
      AuthContextRequestType: authContextRequestType,
      AuthContextInputs: authContextInputs,
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.AUTH_CONTEXT,
      ActionType.REQUEST,
      authContextPayload
    );
  },

  buildRTCSettingsUpdateRequestMessage: (settings: UserSettings) => {
    const settingsUpdatePayload: SettingsUpdateRequest = {
      UserSettings: settings,
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.SETTINGS_UPDATE,
      ActionType.REQUEST,
      settingsUpdatePayload
    );
  },

  getChannelUnavailableError: (): WsError => {
    return new WsError(
      WsErrorTypes.ERROR_TYPE_NEGOTIATION,
      WsErrorCodes.ERROR_SERVICE_UNAVAILABLE,
      ClientErrorCode.RTCChannelTornDown
    );
  },

  buildHealthCheckStatusRequestMessage: (
    healthCheckRequest: HealthCheckRequest
  ) => {
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.HEALTH_CHECK,
      ActionType.REQUEST,
      healthCheckRequest
    );
  },

  buildDnsDomainRecordRequestMessage: (
    domain: string,
    recordType: DNSRecordType
  ) => {
    const resolveDnsRecordRequest = {
      Domain: domain,
      RecordType: recordType,
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.RESOLVE_DNS_RECORD,
      ActionType.REQUEST,
      resolveDnsRecordRequest
    );
  },

  buildMigrateRegistrationCodesRequestMessage: () => {
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.MIGRATE_REGISTRATION_CODES,
      ActionType.RESPONSE,
      ''
    );
  },

  buildDisplayWDUrlResultMessage: (wdUrl: string) => {
    const displayWarpDriveURL: DisplayWarpDriveURL = {
      URL: wdUrl,
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.DISPLAY_WARPDRIVE_URL,
      ActionType.REQUEST,
      displayWarpDriveURL
    );
  },

  buildBrokerAuthenticationResultMessage: (isAuthenticated: boolean) => {
    const brokerAuthenticationResult: BrokerAuthenticationResult = {
      IsAuthenticated: isAuthenticated,
    };
    return SoloNativeRTCUtility.buildRTCChannelMessage(
      Action.BROKER_AUTHENTICATION_RESULT,
      ActionType.REQUEST,
      brokerAuthenticationResult
    );
  },
};
