import { ILogger } from '@/bridge/ILogger';
import { ISessionManager } from '@/bridge/ISessionManager';
import {
  AuthMethod,
  AuthMethodAuthType,
  AuthProvider,
  IAuthenticateSuccessResponse,
} from '@/core/wsbroker/types';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { IAuthInternalStep, INextAuthStep } from './types';
import { IDevice } from '@/bridge/IDevice';
import { PlatformType } from '@/bridge/types/SoloRTCChannelTypes';

interface AuthStepDeterminerProps {
  sessionManager: ISessionManager;
  logger: ILogger;
  device: IDevice;
}

export class AuthStepDeterminer {
  private readonly sessionManager: ISessionManager;
  private readonly logger: ILogger;
  private readonly device: IDevice;

  constructor({ sessionManager, logger, device }: AuthStepDeterminerProps) {
    this.sessionManager = sessionManager;
    this.logger = logger;
    this.device = device;
  }

  static getInternalAuthStepFromAuthMethod(
    authMethod?: AuthMethod
  ): IAuthInternalStep {
    const internalAuthStep: IAuthInternalStep = {
      complete: false,
      url: '/auth/reconnect',
      external: false,
    };
    if (authMethod?.AuthType === AuthMethodAuthType.TOKEN) {
      return internalAuthStep;
    } else if (authMethod?.AuthProvider === AuthProvider.WARP_DRIVE) {
      internalAuthStep.url = '/auth/warpdrive';
      return internalAuthStep;
    } else if (authMethod?.AuthProvider === AuthProvider.SAML_IAM) {
      internalAuthStep.url = '/auth/saml';
      return internalAuthStep;
    } else if (authMethod?.AuthProvider === AuthProvider.IDC) {
      internalAuthStep.url = '/auth/idc';
      return internalAuthStep;
    }

    throw new WsError(
      WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
      WsErrorCodes.ERROR_UNSUPPORTED_AUTH_TYPE
    );
  }

  static getNextStepFromAuthResponse(
    authResponse: IAuthenticateSuccessResponse
  ): INextAuthStep {
    if (authResponse.NextAuthMethods?.[0]) {
      const authMethod = authResponse.NextAuthMethods?.[0];
      if (authMethod.Url) {
        return {
          complete: false,
          external: true,
          url: authMethod.Url,
        };
      } else {
        return {
          complete: false,
          external: false,
          url: '/auth',
        };
      }
    } else if (
      authResponse.AuthToken &&
      authResponse.SessionContext.AuthToken
    ) {
      return {
        complete: true,
        external: false,
      };
    } else {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_UNSUPPORTED_AUTH_TYPE
      );
    }
  }

  isAuthProvider(provider: AuthProvider) {
    return this.sessionManager.get('nextAuthMethod')?.AuthProvider === provider;
  }

  getInternalAuthStepFromSession(): IAuthInternalStep {
    return AuthStepDeterminer.getInternalAuthStepFromAuthMethod(
      this.sessionManager.get('nextAuthMethod')
    );
  }

  // Currently we are enabling reconnection only for solo linux client
  isEligibleForReconnection() {
    return (
      this.sessionManager.isNativeMode() &&
      this.device.getPlatform() === PlatformType.LINUX
    );
  }

  doesResourceExistForReconnection() {
    return !!this.sessionManager.get('resource');
  }

  isTokenValidForReconnection() {
    const storedSessionToken = this.sessionManager.get('authToken');
    return !!(
      storedSessionToken?.Value &&
      storedSessionToken?.ExpirationTimestamp > Date.now()
    );
  }
}
