import { MetricResult, Operation } from '@bridge/types/MetricTypes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { WsError } from '@core/error/WsError';

const OperationToKnownErrorMap: Map<string, ClientErrorCode[]> = new Map<
  string,
  ClientErrorCode[]
>([
  [
    Operation.GetAuthInfo,
    [
      ClientErrorCode.HttpClient3xxNetworkError,
      ClientErrorCode.HttpClient4xxNetworkError,
      ClientErrorCode.HttpClientSocketNetworkError,
      ClientErrorCode.HttpClientTimeoutError,
      ClientErrorCode.HttpClientUnknownNetworkError,
      ClientErrorCode.AuthInfoRegCodeInvalid,
      ClientErrorCode.AuthInfoPlatformDenied,
    ],
  ],
  [
    Operation.GetResources,
    [
      ClientErrorCode.HttpClient3xxNetworkError,
      ClientErrorCode.HttpClient4xxNetworkError,
      ClientErrorCode.HttpClientSocketNetworkError,
      ClientErrorCode.HttpClientTimeoutError,
      ClientErrorCode.HttpClientUnknownNetworkError,
      ClientErrorCode.GetResourceNoResourceFound,
    ],
  ],
  [
    Operation.StartConnect,
    [
      ClientErrorCode.AllocateResourceInvalidState,
      ClientErrorCode.AllocateResourcePoolInsufficientCapacityFailure,
      ClientErrorCode.AllocateResourceSessionCleanupInprogressFailure,
    ],
  ],
  [
    Operation.LocaleUpdate,
    [
      ClientErrorCode.SettingsUpdateUnKnown,
      ClientErrorCode.SettingsUpdateInvalidInput,
    ],
  ],
]);

const AxiosFaultSafeOperations: Operation[] = [
  Operation.DescribeClientCustomization,
];

export function identifyMetricResult(operation: Operation, error: any) {
  if (error instanceof WsError) {
    const knownErrors = OperationToKnownErrorMap.get(operation);
    if (
      knownErrors &&
      error?.clientErrorCode &&
      knownErrors.includes(error?.clientErrorCode)
    ) {
      return MetricResult.Error;
    }
    return MetricResult.Fault;
  } else if (error?.isAxiosError) {
    if (
      error?.code === 'ERR_NETWORK' ||
      error?.code === 'ERR_CANCELED' ||
      AxiosFaultSafeOperations.includes(operation)
    ) {
      return MetricResult.Error;
    } else {
      return MetricResult.Fault;
    }
  }
  return MetricResult.UnhandledException;
}
