import { MetricConstants } from '@core/constants/MetricConstants';
import {
  MetricRequest,
  MetricPoint,
  IMetricPublishResponse,
} from '@/core/metrics/types';
import { ILogger } from '@bridge/ILogger';
import { BrowserPersistentStreamStorage } from '@core/storage/BrowserPersistentStreamStorage';
import { IHttpClient } from '@bridge/IHttpClient';
import { ISessionManager } from '@bridge/ISessionManager';
import { DEFAULT_METRIC_PUBLISH_ENDPOINT } from '@/bridge/constants/RegionConstants';

export class MetricsPublisher {
  readonly METRICS_STORAGE_KEY = 'WS_WEB_CLIENT_METRICS';
  private readonly metricStorage: BrowserPersistentStreamStorage;
  private readonly sessionManager: ISessionManager;
  private readonly logger: ILogger;
  private readonly http: IHttpClient<IMetricPublishResponse>;
  private nextIterationTimeoutId: any;
  private currentIterationBatchPostPromises: Array<Promise<void>>;

  constructor(
    metricStorage: BrowserPersistentStreamStorage,
    http: IHttpClient<IMetricPublishResponse>,
    sessionManager: ISessionManager,
    logger: ILogger
  ) {
    this.metricStorage = metricStorage;
    this.logger = logger;
    this.http = http;
    this.sessionManager = sessionManager;
    this.currentIterationBatchPostPromises = [];

    this.nextIterationTimeoutId = setTimeout(() => {
      this.postMetrics();
    }, MetricConstants.PUBLISHER_INTERVAL);
  }

  public publish(metricPoint: MetricPoint) {
    this.metricStorage.push(
      this.METRICS_STORAGE_KEY,
      JSON.stringify(metricPoint)
    );
  }

  public async flush() {
    this.logger.info(`Starting Metrics flush request`);
    if (this.currentIterationBatchPostPromises.length === 0) {
      this.logger.info(`Triggering metrics publish`);
      clearTimeout(this.nextIterationTimeoutId);
      this.postMetrics();
    }
    this.logger.info(`Awaiting completion of metrics publish`);
    await Promise.all(this.currentIterationBatchPostPromises);
    this.logger.info(`Completed metrics flush`);
  }

  private postMetrics() {
    this.logger.info(`Started Metric publisher task`);
    this.currentIterationBatchPostPromises = [];
    let batchMetrics = this.metricStorage.batchPop(
      this.METRICS_STORAGE_KEY,
      MetricConstants.MAX_METRIC_PUBLISHER_SIZE_BYTES
    );
    while (batchMetrics.length !== 0) {
      const payload = this.generatePayload(batchMetrics);
      const metricRequest = this.createMetricRequest(payload);
      const postPromise = this.post(metricRequest);
      this.currentIterationBatchPostPromises.push(postPromise);
      batchMetrics = this.metricStorage.batchPop(
        this.METRICS_STORAGE_KEY,
        MetricConstants.MAX_METRIC_PUBLISHER_SIZE_BYTES
      );
    }

    this.logger.info(`Setting up next trigger for publishing metrics`);
    this.nextIterationTimeoutId = setTimeout(() => {
      this.postMetrics();
    }, MetricConstants.PUBLISHER_INTERVAL);
  }

  private async post(metricRequest: MetricRequest) {
    const metricPayload = JSON.stringify(metricRequest);
    let retryIndex = 0;
    let isRetryNeeded = true;
    while (
      retryIndex < MetricConstants.MAX_METRIC_PUBLISH_RETRY &&
      isRetryNeeded
    ) {
      await this.http
        .post(
          this.getPublisherEndPointUrl(),
          metricPayload,
          MetricConstants.METRIC_PUBLISH_HTTP_CONFIG
        )
        .then((response) => {
          if (response.status !== 200) {
            ++retryIndex;
            this.logger.error(
              `Metric batch item publish failed with error ${response.status}. Proceeding to retry`
            );
          } else {
            isRetryNeeded = false;
          }
        })
        .catch(() => {
          this.logger.error(
            'Metric batch item publish failed. Aborting publisher task'
          );
          isRetryNeeded = false;
        });
    }
  }

  private generatePayload(metricStringArray: string[]) {
    const metricPointArray = [];
    for (let index = 0; index < metricStringArray.length; ++index) {
      metricPointArray.push(JSON.parse(metricStringArray[index]));
    }
    let payload = '';
    try {
      payload = JSON.stringify(metricPointArray);
    } catch (e) {
      this.logger.error(
        `Error publishing web client logs ${JSON.stringify(metricStringArray)}`
      );
    }
    return payload;
  }

  private createMetricRequest(metricString: string): MetricRequest {
    return {
      MetricReport: metricString,
      Version: MetricConstants.BROWSER_METRICS_REQUEST_API_VERSION,
    };
  }

  private getPublisherEndPointUrl(): string {
    const baseUrl =
      this.sessionManager.get('region')?.metricEndpoint ??
      DEFAULT_METRIC_PUBLISH_ENDPOINT;
    return baseUrl + MetricConstants.BROWSER_METRICS_REQUEST_API_URL_PATH;
  }
}
