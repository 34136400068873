import { AppConstants } from '@bridge/constants/AppConstants';
import { IDevice } from '@bridge/IDevice';
import { MetricConstants } from '@core/constants/MetricConstants';
import { SessionProtocols } from '@bridge/types/SessionTypes';
import {
  PlatformInfo,
  PlatformSupportInfo,
  SupportInfo,
} from '@bridge/types/DeviceTypes';
import platform from 'platform';
import bowser from 'bowser';
import { IPersistentStorage } from '@/bridge/IPersistentStorage';
import { generateUUID } from '@/bridge/utility';
import {
  AuthCapability,
  AuthStrategy,
  AuthType,
  PlatformType,
  ProxyType,
} from '@/bridge/types/SoloRTCChannelTypes';
import { LogLevel } from '@core/constants/LoggerConstants';
import { IRegion } from '@/bridge/types/RegionTypes';
import { Device } from './constants';

enum SupportedPlatformNames {
  chrome = 'chrome',
  firefox = 'firefox',
  'microsoft edge' = 'microsoft edge',
  safari = 'safari',
}

type PlatformToSupportVersionMap = {
  [key in SupportedPlatformNames]?: number;
};

type ProtocolToSupportPlatformVersionMap = {
  [key in SessionProtocols]: PlatformToSupportVersionMap;
};

const DEFAULT_PLATFORM_TO_SUPPORT_VERSION: PlatformToSupportVersionMap = {
  chrome: 55,
  firefox: 48,
  'microsoft edge': 55,
};

const PROTOCOL_TO_SUPPORT_PLATFORM_VERSION: ProtocolToSupportPlatformVersionMap =
  {
    [SessionProtocols.PCOIP]: {
      ...DEFAULT_PLATFORM_TO_SUPPORT_VERSION,
    },
    [SessionProtocols.MAXIBON]: {
      ...DEFAULT_PLATFORM_TO_SUPPORT_VERSION,
      safari: 0,
    },
    [SessionProtocols.WSP]: {
      ...DEFAULT_PLATFORM_TO_SUPPORT_VERSION,
    },
  };

// TODO: need to test on all OS to verify this works
// TODO: or work with PMs to determine if we will support more platforms
const SUPPORTED_OS = ['Windows', 'Linux', 'OS X'];
const DEVICE_UUID_KEY = 'device_uuid';

export class BrowserPlatformDevice implements IDevice {
  private readonly store: IPersistentStorage;
  private readonly supportedProtocols: string[] = [
    SessionProtocols.MAXIBON.toLowerCase(),
    SessionProtocols.PCOIP.toLowerCase(),
  ];

  private readonly _isNiva: boolean;
  private readonly vendorName: string | undefined;
  private readonly modelNumber: string | undefined;
  private readonly deviceUuid: string;

  constructor(store: IPersistentStorage) {
    this.store = store;
    this._isNiva = false;
    const nivaMatch = window.navigator.userAgent?.match(
      Device.NIVA_USER_AGENT_PATTERN
    );
    if (nivaMatch && nivaMatch.length > 0) {
      this._isNiva = true;
      this.vendorName = Device.NIVA_VENDOR_NAME;
      this.modelNumber = nivaMatch[1];
      this.deviceUuid = nivaMatch[2]; // Device Serial Number
    } else {
      this.deviceUuid = this.store.get(DEVICE_UUID_KEY) as string;
      if (!this.deviceUuid) {
        this.deviceUuid = generateUUID();
        this.store.set(DEVICE_UUID_KEY, this.deviceUuid);
      }
    }
  }

  // Set methods are no-op for BrowserDevice type. Most of the attributes are static
  setPlatformVersion(platformVersion: string) {}
  setProtocolVersion(protocolVersion: string) {}
  setHostOS(hostOS: PlatformType) {}
  setHostOSVersion(hostOSVersion: string) {}
  setDeviceUUID(deviceUUID: string) {}
  setAuthCapabilities(authCapabilities?: AuthCapability[]) {}
  setProxySettings(proxyType: ProxyType) {}
  setSupportedProtocols(supportedProtocols?: SessionProtocols[]) {}
  setModelNumber(modelNumber: string) {}
  setVendorName(vendorName: string) {}
  setIsCanaryRun(isCanaryRun: boolean) {}
  setIsOemHandshaked(isOemHandshaked: boolean) {}

  getAuthCapabilities(region?: IRegion): AuthCapability[] {
    const wdCredsCapability = {
      AuthType: AuthType.WARP_DRIVE,
      AuthStrategy: AuthStrategy.CREDENTIAL,
    };
    const wdMFACapability = {
      AuthType: AuthType.WARP_DRIVE,
      AuthStrategy: AuthStrategy.MFA,
    };
    const deviceAuthCapability = {
      AuthType: AuthType.DEVICE_AUTH,
      AuthStrategy: AuthStrategy.CERTIFICATE,
    };
    const samlAuthCapability = {
      AuthType: AuthType.SAML,
      AuthStrategy: AuthStrategy.SAML,
    };
    const idcAuthCapability = {
      AuthType: AuthType.IDC,
      AuthStrategy: AuthStrategy.IDC,
    };
    const virtualSmartCardCapability = {
      AuthType: AuthType.SAML,
      AuthStrategy: AuthStrategy.SEAMLESS,
    };

    const capabilities = [
      wdCredsCapability,
      wdMFACapability,
      deviceAuthCapability,
      idcAuthCapability,
    ];

    if (region?.supportCBA) {
      capabilities.push(samlAuthCapability);
      capabilities.push(virtualSmartCardCapability);
    } else if (region?.supportSAML) {
      capabilities.push(samlAuthCapability);
    }

    return capabilities;
  }

  getDeviceUUID(): string {
    return this.deviceUuid;
  }

  getModelNumber(): string | undefined {
    return this.modelNumber;
  }

  getIsOemHandshaked(): boolean {
    return false;
  }

  getVendorName(): string | undefined {
    return this.vendorName;
  }

  getHostOS(): string | undefined {
    return platform.os?.family;
  }

  getHostOSVersion(): string | undefined {
    return platform.os?.version;
  }

  getPlatform(): PlatformType | undefined {
    return PlatformType.WEB;
  }

  getPlatformName(): string | undefined {
    return `${platform.name}`;
  }

  getPlatformVersion(): string | undefined {
    return `${platform.version}`;
  }

  getPlatformAppName(): string {
    return this.isNiva()
      ? MetricConstants.NIVA_PLATFORM_APP_NAME
      : MetricConstants.BROWSER_PLATFORM_APP_NAME;
  }

  getProductVersion(): string {
    return AppConstants.WEB_CLIENT_APP_VERSION;
  }

  // Ideally this depends on underlying protocol. But since Web mostly focusses on WSP going forward, will keep the logic simple
  getProtocolVersion(): string {
    return AppConstants.WEB_CLIENT_WSP_PROTOCOL_VERSION;
  }

  // Does not apply to Browser
  getProxyType(): string {
    return ProxyType.NoProxy;
  }

  // ToDo: having issue finding common code that works in edge and firefox
  is64BitClient(): boolean {
    return true;
  }

  // ToDo: Remove this as its not used anymore
  getSupportInfo(protocols?: SessionProtocols[]): PlatformSupportInfo {
    const platformInfo = this.getPlatformInfo();
    const supportInfo = this.getSupportInfoByPlatform(
      platformInfo,
      protocols ?? Object.values(SessionProtocols)
    );

    return {
      ...platformInfo,
      supportInfo,
    };
  }

  isCurrentPlatformSupported(
    protocols?: SessionProtocols[],
    region?: IRegion
  ): boolean {
    if (
      !protocols ||
      !protocols[0] ||
      !this.supportedProtocols ||
      this.supportedProtocols.length === 0 ||
      !this.isCurrentRegionSupported(region)
    ) {
      return false;
    }
    const protocolToCompare = protocols[0];
    if (this.supportedProtocols.includes(protocolToCompare.toLowerCase())) {
      if (
        protocolToCompare.toLowerCase() === SessionProtocols.PCOIP.toLowerCase()
      ) {
        return region?.isWebPcoIPSupported ?? false;
      } else {
        return true;
      }
    }
    return false;
  }

  // Web Client supports all regions.
  isCurrentRegionSupported(region?: IRegion): boolean {
    return true;
  }

  isCurrentOSSupported(): boolean {
    if (bowser.android || bowser.ios) return false;

    const os = this.getHostOS();
    const pattern = new RegExp(`${SUPPORTED_OS.join('|')}`);
    const test = os?.match(pattern);
    return test !== null;
  }

  saveLog(message: string, logLevel: LogLevel) {
    const time = new Date().toISOString();
    message = time + '::' + message;
    switch (logLevel) {
      case LogLevel.Info:
        return console.log(message);
      case LogLevel.Warn:
        return console.warn(message);
      case LogLevel.Error:
        return console.error(message);
      case LogLevel.Fatal:
        return console.error(message);
    }
  }

  isNiva(): boolean {
    return this._isNiva;
  }

  private getPlatformInfo(): PlatformInfo {
    return {
      name: platform.name?.trim().toLocaleLowerCase(),
      version:
        platform.version === undefined
          ? undefined
          : Number(platform.version.match(/\d+/g)?.[0]),
    };
  }

  private getSupportInfoByPlatform(
    platformInfo: PlatformInfo,
    protocols: SessionProtocols[]
  ): SupportInfo {
    const { name, version } = platformInfo;
    if (
      name === undefined ||
      !(name in SupportedPlatformNames) ||
      version === undefined
    ) {
      return {};
    }

    const supportInfo: SupportInfo = {};
    for (const protocol of protocols) {
      const lowestSupportVersion =
        PROTOCOL_TO_SUPPORT_PLATFORM_VERSION[protocol][
          name as SupportedPlatformNames
        ];
      supportInfo[protocol] = {
        support:
          lowestSupportVersion !== undefined && version >= lowestSupportVersion,
        lowestSupportVersion,
      };
    }

    return supportInfo;
  }
}
