import { useEffect, useState } from 'react';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import {
  HealthCheckPorts,
  HealthCheckProtocol,
} from '@/bridge/constants/HealthCheckConstants';
import { useSessionStore } from '@/stores/session';
import { t } from 'i18next';
import {
  HealthCheckRequest,
  HealthCheckResponse,
  NetworkConnectivityResult,
} from '@/bridge/types/SoloRTCChannelTypes';

export interface HealthCheckData {
  message: String;
  onDismiss: () => void;
}
const MaximumWarningRoundTripTimeMillis = 375;

const useHealthCheck = () => {
  const [healthCheckData, setHealthCheckData] = useState<HealthCheckData[]>([]);
  const region = useSessionStore((state) => state.region);
  const regionLocalKey = region?.localeKey;
  const healthcheckRegionName = t(regionLocalKey!);
  const healthCheckHostname = region?.healthCheckHostname;
  const rtcChannel = CoreFactory.getRTCChannel();
  const [healthCheckResponse, setHealthCheckResponse] =
    useState<HealthCheckResponse>();
  const [httpsResponse, setHttpsResponse] = useState('');
  const [isAlertShown, setIsAlertShown] = useState<Boolean>(false);

  useEffect(() => {
    if (region) {
      setHealthCheckData([]);
      requestHealthCheckStatus();
      fetchHttpData();
    }
  }, [region]);

  useEffect(() => {
    if (healthCheckResponse && !isAlertShown) {
      setIsAlertShown(true);
      const tcpResult = checkPortHealth(
        healthCheckResponse.TcpConnectivity,
        HealthCheckProtocol.TCP
      );
      const udpResult = checkPortHealth(
        healthCheckResponse.UdpConnectivity,
        HealthCheckProtocol.UDP
      );
      const tcpRoundTripTime = healthCheckResponse.TcpRoundTrip.toString();
      const udpRoundTripTime = healthCheckResponse.UdpRoundTrip.toString();
      if (tcpResult.areAnyPortsUnhealthy)
        addHealthCheckStatus(tcpResult.alertStatus);
      if (udpResult.areAnyPortsUnhealthy)
        addHealthCheckStatus(udpResult.alertStatus);
      if (healthCheckResponse.TcpRoundTrip > MaximumWarningRoundTripTimeMillis)
        addHealthCheckStatus(
          t('ws-healthcheck-tcp-roundtrip', { tcpRoundTripTime })
        );
      if (healthCheckResponse.UdpRoundTrip > MaximumWarningRoundTripTimeMillis)
        addHealthCheckStatus(
          t('ws-healthcheck-udp-roundtrip', { udpRoundTripTime })
        );
    }
  }, [healthCheckResponse]);

  useEffect(() => {
    if (httpsResponse && httpsResponse !== 'healthy') {
      addHealthCheckStatus(
        t('ws-healthcheck-connection-failed', { healthcheckRegionName })
      );
    }
  }, [httpsResponse]);

  const requestHealthCheckStatus = async () => {
    if (rtcChannel) {
      const healthCheckRequest = createHealthCheckRequest(healthCheckHostname!);
      await new Promise<HealthCheckResponse>((resolve, reject) => {
        rtcChannel.requestHealthCheckStatus(healthCheckRequest, (payload) => {
          const rtcHealthCheckResponse = payload as HealthCheckResponse;
          setHealthCheckResponse(rtcHealthCheckResponse);
          resolve(rtcHealthCheckResponse);
        });
      });
    }
  };

  const checkPortHealth = (
    networkConnectivityResult: NetworkConnectivityResult[],
    protocol: string
  ) => {
    let areAnyPortsUnhealthy: boolean = false;
    let alertStatus: string = '';
    const unhealthyPorts: string[] = [];
    const healthyPorts: string[] = [];
    networkConnectivityResult.forEach((networkConnectivity) => {
      if (!networkConnectivity.IsHealthy) {
        areAnyPortsUnhealthy = true;
        unhealthyPorts.push(networkConnectivity.Port.toString());
      } else {
        healthyPorts.push(networkConnectivity.Port.toString());
      }
    });

    if (unhealthyPorts.length === 2 && protocol === HealthCheckProtocol.TCP)
      alertStatus = t('ws-healthcheck-tcp-fail');
    if (unhealthyPorts.length === 2 && protocol === HealthCheckProtocol.UDP)
      alertStatus = t('ws-healthcheck-udp-fail');

    if (unhealthyPorts.length === 1) {
      const connectedPort = healthyPorts.join(', ');
      const failedPort = unhealthyPorts.join(', ');
      if (protocol === 'tcp')
        alertStatus = t('ws-healthcheck-tcp-partial', {
          connectedPort,
          failedPort,
        });
      if (protocol === 'udp')
        alertStatus = t('ws-healthcheck-udp-partial', {
          connectedPort,
          failedPort,
        });
    }

    return { areAnyPortsUnhealthy, alertStatus, protocol };
  };

  const fetchHttpData = async () => {
    try {
      const res = await fetch(region?.wsBrokerEndpoint + '/ping');
      const data = await res.text();
      if (typeof data === 'string') {
        setHttpsResponse(data);
      } else {
        throw new Error('Invalid response');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addHealthCheckStatus = (message: String) => {
    setHealthCheckData((previousHealthCheckData) => [
      ...previousHealthCheckData,
      {
        message,
        onDismiss: () => {},
      },
    ]);
  };

  const onDismissAlert = (message: String) => {
    setHealthCheckData((prevData) =>
      prevData.filter((data) => data.message !== message)
    );
  };

  const createHealthCheckRequest = (
    healthCheckHostname: string
  ): HealthCheckRequest => {
    const request: HealthCheckRequest = {
      Hostname: healthCheckHostname,
      NetworkConfig: [
        {
          Protocol: HealthCheckProtocol.TCP,
          Ports: [HealthCheckPorts.PORT_PCOIP, HealthCheckPorts.PORT_WSP],
        },
        {
          Protocol: HealthCheckProtocol.UDP,
          Ports: [HealthCheckPorts.PORT_PCOIP, HealthCheckPorts.PORT_WSP],
        },
      ],
    };
    return request;
  };

  return { healthCheckData, onDismissAlert };
};

export default useHealthCheck;
