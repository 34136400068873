import { useCallback, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { i18nInit } from '@/i18n';
import { CookieCompliance } from '@bridge/CookieCompliance';
import Main from '@views/Main';
import Registration from '@views/Registration';
import Login from '@views/Login';
import AuthRoute from '@/views/Auth/AuthRoute';
import Session from '@views/Session';
import Stream from '@views/Stream';
import Disconnect from '@views/Disconnect';
import Error from '@views/Error';
import SelfService from '@views/SelfService';
import PlatformBoundary from '@/components/PlatformBoundary';
import { CoreFactory } from './bridge/factory/CoreFactory';
import {
  MetricResult,
  Operation,
  STREAMING_ON,
  STREAMING_ON_OPTIONS,
} from './bridge/types/MetricTypes';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';
import { useErrorHandler } from '@hooks/useErrorHandler';
import { usePageTitle } from './hooks/usePageTitle';
import {
  GetPlatformDetailsResponse,
  LanguageOptions,
} from '@bridge/types/SoloRTCChannelTypes';
import { useSessionStore } from '@stores/session';
import { useTheme } from './stores/theme';
import { Mode } from '@cloudscape-design/global-styles';
import { WsError } from '@core/error/WsError';
import { useBreakpoint } from '@amzn/aws-euc-ui';
import { Localization } from '@core/localization/Localization';
import ConnectStatusWithoutLogoComponent from '@components/ConnectStatus/ConnectStatusWithoutLogo';
import useMigrateRegistrationCodes from '@/hooks/useMigrateRegistrationCodesHandler';
import { useReportHeartbeatTask } from './hooks/useReportHeartbeatTask';

// init localization
i18nInit();

// init query client for query context provider
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});
const store = CoreFactory.getPersistentStorage();
const logger = CoreFactory.getLogger();
const metrics = CoreFactory.getMetrics();
const rtcChannel = CoreFactory.getRTCChannel();
const device = CoreFactory.getDevice();

const App = () => {
  const { showError } = useErrorHandler();
  usePageTitle();
  const isCobrandingEnabled = useSessionStore(
    (state) => state.isCobrandingEnabled
  );
  const setSession = useSessionStore((state) => state.setSession);
  const updateTheme = useTheme((state) => state.updateTheme);
  const { isDesktopOrLaptop } = useBreakpoint();
  const [rtcNegotiationStart, setRtcNegotiationStart] = useState(false);
  const [rtcNegotiationComplete, setRtcNegotiationComplete] = useState(false);

  // Initialize the heartbeat hook so that it can stay alive in background for Solo
  useReportHeartbeatTask();

  // This code is only for PcoIP. Can be removed once PcoIP is deprecated by 08/23
  const handleStreamDisconnects = () => {
    const crushedDuringStreaming =
      store.get(STREAMING_ON) === STREAMING_ON_OPTIONS.ON;
    if (crushedDuringStreaming) {
      logger.info('Unexpected streaming termination detected');
      store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
      metrics.emit(Operation.Streaming, MetricResult.Fault);
    }
  };

  const discoverNativeClientRTCChannel = () => {
    logger.info(
      `Initiating RTC Channel negotiation. Checking Channel Availability: ${SoloNativeRTCChannel.isChannelAvailable()}`
    );
    if (
      rtcChannel &&
      SoloNativeRTCChannel.isChannelAvailable() &&
      !rtcNegotiationStart
    ) {
      try {
        logger.info(
          `Initiating RTC Channel negotiation. WebClient is running in embedded browser: ${window.location.hostname}`
        );
        const userSettings = {
          Locale: getCurrentLocaleAsLanguageOptions(),
        };
        rtcChannel.requestPlatformInfo(userSettings, updateDeviceDetails);
        setRtcNegotiationStart(true);
      } catch (e) {
        logger.error(`RTCNegotiation failed with error ${e}`);
        metrics.emit(Operation.Negotiation, MetricResult.Fault, e as WsError);
        showError(e);
      }
    }
  };

  const updateDeviceDetails = useCallback(
    (rtcResponse: GetPlatformDetailsResponse) => {
      logger.info(
        'Received negotiation response. Proceeding to app initialization'
      );

      device.setAuthCapabilities(rtcResponse?.AuthCapabilities);
      device.setDeviceUUID(rtcResponse?.DeviceId);
      device.setHostOS(rtcResponse?.Platform);
      device.setHostOSVersion(rtcResponse?.PlatformVersion);
      device.setPlatformVersion(rtcResponse?.Version);
      device.setProtocolVersion(rtcResponse?.ProtocolVersion);
      device.setProxySettings(rtcResponse?.PlatformSettings?.ProxyType);
      device.setSupportedProtocols(
        rtcResponse?.PlatformSettings?.SupportedProtocols
      );
      device.setModelNumber(rtcResponse?.ModelNumber);
      device.setVendorName(rtcResponse?.VendorName);
      device.setIsCanaryRun(rtcResponse?.IsCanaryRun);
      device.setIsOemHandshaked(rtcResponse?.IsOemHandshaked);
      metrics.emit(Operation.Negotiation, MetricResult.Success);
      setSession({ isNegotiationComplete: true });
      setRtcNegotiationComplete(true);
      logger.initializeLogUploader?.();
    },
    [device, metrics, setSession, setRtcNegotiationComplete]
  );

  const { requestRegistrationCodesMigration } = useMigrateRegistrationCodes();

  useEffect(() => {
    if (!SoloNativeRTCChannel.isChannelAvailable()) {
      CookieCompliance.reload();
    }
    handleStreamDisconnects();
    discoverNativeClientRTCChannel();
    requestRegistrationCodesMigration();
  }, []);

  useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) =>
        updateTheme(
          e.matches ? Mode.Dark : Mode.Light,
          isCobrandingEnabled,
          !isDesktopOrLaptop
        )
      );

    // Setup dark/light mode for the first time
    updateTheme(
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? Mode.Dark
        : Mode.Light,
      isCobrandingEnabled,
      !isDesktopOrLaptop
    );

    // Remove listener
    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', () => {});
    };
  }, [isDesktopOrLaptop, isCobrandingEnabled]);

  if (rtcNegotiationStart && !rtcNegotiationComplete) {
    return <ConnectStatusWithoutLogoComponent />;
  } else {
    return (
      <PlatformBoundary>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/registration" element={<Registration />} />
            <Route path="/login" element={<Login />} />
            <Route path="/disconnect" element={<Disconnect />} />
            <Route path="/error" element={<Error />} />
            <Route path="/auth/*" element={<AuthRoute />} />
            <Route path="/session" element={<Session />} />
            <Route path="/stream" element={<Stream />} />
            {SoloNativeRTCChannel.isChannelAvailable() && (
              <Route path="/self-service" element={<SelfService />} />
            )}
            <Route path="*" element={<Main />} />
          </Routes>
        </QueryClientProvider>
      </PlatformBoundary>
    );
  }
};

const getCurrentLocaleAsLanguageOptions = () => {
  let currentLocale = LanguageOptions.EN_US;
  let currentLocalAsLngOptions;
  try {
    currentLocalAsLngOptions = Localization.getCurrentLocale();
    currentLocale = currentLocalAsLngOptions as unknown as LanguageOptions;
  } catch (error: any) {
    logger.warn(
      `Error converting current locale ${currentLocalAsLngOptions} to rtc listener format. Sending default option`
    );
  }
  return currentLocale;
};

export default App;
