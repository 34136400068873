import { IHttpClient } from '@bridge/IHttpClient';
import { ILogger } from '@bridge/ILogger';
import { IDevice } from '@/bridge/IDevice';
import {
  WsBrokerAPI,
  WSBrokerServiceConstants,
} from '@/core/wsbroker/Constants';
import { ServiceUtility } from '@/core/wsbroker/ServiceUtility';
import {
  IAllocateResourceSuccessResponse,
  IAuthenticateRequest,
  IAuthenticateSuccessResponse,
  IAuthInfoSuccessResponse,
  IGetResourcesSuccessResponse,
  IPerformResourceActionRequest,
  IPerformResourceActionResponse,
  IGetHeartBeatInfoSuccessResponse,
} from '@/core/wsbroker/types';
import {
  AuthCapability,
  PlatformType,
} from '@bridge/types/SoloRTCChannelTypes';
import { IRegion } from '@bridge/types/RegionTypes';

export class WSBrokerService {
  private readonly httpClient: IHttpClient<any>;
  private readonly logger: ILogger;
  private readonly device: IDevice;

  constructor(httpClient: IHttpClient<any>, logger: ILogger, device: IDevice) {
    this.httpClient = httpClient;
    this.logger = logger;
    this.device = device;
  }

  async getHeartBeatInfo(
    authToken: string,
    sessionId: string,
    regCode: string,
    region: IRegion
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.GetHeartBeatInfo
    );
    const httpConfig = ServiceUtility.getWsBrokerHttpConfig(region, headers);
    this.logger.info(
      `Calling WSBroker:GetHeartBeatInfo API with arguments: regCode::${regCode}}`
    );
    return await this.httpClient
      .post(
        '/',
        ServiceUtility.getHeartBeatInfoRequestBody(authToken, sessionId),
        httpConfig
      )
      .then(({ data }) => {
        return data as IGetHeartBeatInfoSuccessResponse;
      })
      .catch((error) => {
        this.logger.error(
          `Received error for WSBrokerService GetHeartBeatInfo::${error?.message}`
        );
        throw ServiceUtility.transformServiceError(
          error,
          WsBrokerAPI.GetHeartBeatInfo
        );
      });
  }

  async fetchAuthInfo(
    regCode: string,
    isConnectionAlias: boolean,
    region: IRegion,
    clientVersion: string,
    clientName: string,
    platformType: PlatformType,
    authCapabilities: AuthCapability[],
    codeChallenge: string
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.GetAuthInfo
    );
    const httpConfig = ServiceUtility.getWsBrokerHttpConfig(region, headers);
    this.logger.info(
      `Retrieving authentication context for regCode:${regCode}, device:${this.device.getDeviceUUID()}, version:${clientVersion} in region:${
        region.endpoint
      }`
    );

    return await this.httpClient
      .post(
        '/',
        ServiceUtility.getAuthInfoRequestBody({
          regCode,
          isConnectionAlias,
          clientVersion,
          clientName,
          platformType,
          authCapabilities,
          vendorName: this.device.getVendorName(),
          modelNumber: this.device.getModelNumber(),
          deviceUUID: this.device.getDeviceUUID(),
          codeChallenge,
        }),
        httpConfig
      )
      .then(({ data }) => {
        return data as IAuthInfoSuccessResponse;
      })
      .catch((error) => {
        this.logger.error(
          `Received error for WSBrokerService fetchAuthInfo::${error?.message}`
        );
        throw ServiceUtility.transformServiceError(
          error,
          WsBrokerAPI.GetAuthInfo
        );
      });
  }

  async authenticate(
    regCode: string,
    region: IRegion,
    authenticateRequest: IAuthenticateRequest
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.Authenticate
    );
    const httpConfig = ServiceUtility.getWsBrokerHttpConfig(region, headers);
    this.logger.info(
      `Authenticating workspace with registration code:${regCode} in region:${region.endpoint}`
    );
    authenticateRequest.Version = WSBrokerServiceConstants.ApiVersion;
    return await this.httpClient
      .post('/', authenticateRequest, httpConfig)
      .then(({ data }) => {
        return data as IAuthenticateSuccessResponse;
      })
      .catch((error) => {
        this.logger.error(
          `Received error for WSBrokerService Authenticate::${error?.message}`
        );
        throw ServiceUtility.transformServiceError(
          error,
          WsBrokerAPI.Authenticate
        );
      });
  }

  async getResources(
    authToken: string,
    sessionId: string,
    regCode: string,
    region: IRegion
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.GetResources
    );
    const httpConfig = ServiceUtility.getWsBrokerHttpConfig(region, headers);
    this.logger.info(
      `Retrieving all resources for registration code:${regCode} device:${this.device.getDeviceUUID()} and session:${sessionId} and region:${
        region.endpoint
      }`
    );

    return await this.httpClient
      .post(
        '/',
        ServiceUtility.getResourcesRequestBody(authToken, sessionId),
        httpConfig
      )
      .then(({ data }) => {
        return data as IGetResourcesSuccessResponse;
      })
      .catch((error) => {
        this.logger.error(
          `Received error for WSBrokerService GetResources::${error?.message}`
        );
        throw ServiceUtility.transformServiceError(
          error,
          WsBrokerAPI.GetResources
        );
      });
  }

  async allocateResource(
    authToken: string,
    sessionId: string,
    resourceId: string,
    resourceProtocol: string,
    regCode: string,
    platformType: PlatformType,
    region: IRegion
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.PerformResourceAction
    );
    const httpConfig = ServiceUtility.getWsBrokerHttpConfig(region, headers);
    this.logger.info(
      `Allocating workspace region with registration code:${regCode}, device:${this.device.getDeviceUUID()}, session:${sessionId}, id:${resourceId}, protocol:${resourceProtocol}, region:${
        region.endpoint
      }`
    );

    return await this.httpClient
      .post(
        '/',
        ServiceUtility.getAllocateResourceRequestBody(
          sessionId,
          authToken,
          resourceId,
          resourceProtocol,
          platformType,
          this.device.getDeviceUUID()
        ),
        httpConfig
      )
      .then(({ data }) => {
        return data as IAllocateResourceSuccessResponse;
      })
      .catch((error) => {
        this.logger.error(
          `Received error from WSBrokerService AllocateResource::${error?.message}`
        );
        throw ServiceUtility.transformServiceError(
          error,
          WsBrokerAPI.AllocateResource
        );
      });
  }

  async performResourceAction(
    regCode: string,
    region: IRegion,
    requestBody: IPerformResourceActionRequest
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.PerformResourceAction
    );
    const httpConfig = ServiceUtility.getWsBrokerHttpConfig(region, headers);
    this.logger.info(
      `Modifying state for workspace with registration code:${regCode} for region:${
        region.endpoint
      } on device:${this.device.getDeviceUUID()}, with action:${JSON.stringify(
        requestBody
      )}`
    );

    return await this.httpClient
      .post('/', requestBody, httpConfig)
      .then(({ data }) => {
        return data as IPerformResourceActionResponse;
      })
      .catch((error) => {
        this.logger.error(
          `Received error from WSBrokerService PerformResourceAction::${error?.message}`
        );
        throw ServiceUtility.transformServiceError(
          error,
          WsBrokerAPI.PerformResourceAction
        );
      });
  }
}
