import { IRTCChannel } from '@bridge/IRTCChannel';
import {
  Action,
  AuthContextInputs,
  AuthContextRequestType,
  OnSuccessRTCCallback,
  RTCChannelMessage,
  SessionState,
  UserSettings,
  HealthCheckRequest,
  DNSRecordType,
  ResolveDNSDomainResponse,
  CustomizationConfig,
  ExternalAuthRedirectConfig,
  LogUploadData,
  LogUploadDetails,
  PlatformType,
} from '@bridge/types/SoloRTCChannelTypes';
import { SoloNativeRTCUtility } from '@core/rtcChannel/SoloNativeRTCUtility';
import { ILogger } from '@bridge/ILogger';
import { SoloNativeRTCHandler } from '@core/rtcChannel/SoloNativeRTCHandler';
import { SessionProtocols } from '@bridge/types/SessionTypes';
import { LogLevel } from '@core/constants/LoggerConstants';
import { osname } from 'bowser';

/*
 * WebClient ---> Solo communication
 *     1. App initialization:     GET_PLATFORM_DETAILS         - Retrieves platform details and update session store
 *     2. Post Customization:     STATE_CHANGE (CUSTOMIZATION) - Triggers solo to open custom support link if any, in system browser
 *     3. Reconnect:              AUTH_CONTEXT                 - Request solo for customer credentials for reconnection
 *     4. Post SessionProv :      STREAM_CONTEXT               - Request solo to transform AllocateResource response.
 *     5. Pre Stream:             STREAM                       - Request Solo to start streaming
 *     6. Post SelfSrvc success:  DISCONNECT                   - Triggers solo to start disconnection in case of reboot/Increase disk size self srvc actions
 *     7. Post Language Change:   SETTINGS_UPDATE              - Update solo of change in language. [ToDo: No longer needed. Can be removed]
 *     8. Post Logging:           PUBLISH_LOG                  - Save logs to solo file system.
 * Solo ---> WebClient communication
 *     2. Publish Metric:   PUBLISH_METRIC
 * */
export class SoloNativeRTCChannel implements IRTCChannel {
  private static readonly SoloCallTimeOutInMs = 5000;

  private readonly windowAsAny: any;
  private readonly logger: ILogger;
  private readonly rtcHandler: SoloNativeRTCHandler;

  /*
    There is no use case where we send multiple message types in a single streaming session.
    Hence this implementation should be sufficient to handle action callbacks
  */
  private readonly rtcActionToCallbackMap: Map<
    Action,
    OnSuccessRTCCallback | undefined
  >;

  constructor(logger: ILogger, rtcHandler: SoloNativeRTCHandler) {
    this.logger = logger;
    this.windowAsAny = window as any;
    this.rtcHandler = rtcHandler;
    this.rtcActionToCallbackMap = new Map<Action, OnSuccessRTCCallback>();
    if (SoloNativeRTCChannel.isChannelAvailable()) {
      this.setupListener();
    }
  }

  static isChannelAvailable(): boolean {
    return (
      (window as any)?.webkit?.messageHandlers?.SoloBrowserCall?.postMessage !==
      undefined
    );
  }

  static getPlatform(): PlatformType {
    switch (osname) {
      case 'macOS': {
        return PlatformType.MACOS;
      }
      case 'Windows': {
        return PlatformType.WINDOWS;
      }
      case 'Linux': {
        return PlatformType.LINUX;
      }
      case 'iOS': {
        return PlatformType.IOS;
      }
      default: {
        return PlatformType.WEB;
      }
    }
  }

  static publishLogMessage(message: string, logLevel: LogLevel): boolean {
    const logRtcMessage = SoloNativeRTCUtility.buildRTCPublishLogRequestMessage(
      message,
      logLevel
    );
    const logRtcString = JSON.stringify(logRtcMessage);
    if (SoloNativeRTCChannel.isChannelAvailable()) {
      (window as any).webkit?.messageHandlers?.SoloBrowserCall?.postMessage(
        logRtcString
      );
      return true;
    }
    return false;
  }

  async sendLogUploadTrigger(timeoutInMs?: number) {
    const logRtcMessage =
      SoloNativeRTCUtility.buildRTCLogUploadTriggerMessage();
    return await this.createPromise<LogUploadDetails>(
      logRtcMessage,
      timeoutInMs
    );
  }

  async sendEligibleLogNameMessage(
    eligibleLogFileName: string,
    timeoutInMs?: number
  ) {
    const logUploadTriggerRtcMessage =
      SoloNativeRTCUtility.buildRTCLogUploadPublishMessage(eligibleLogFileName);
    return await this.createPromise<LogUploadData>(
      logUploadTriggerRtcMessage,
      timeoutInMs
    );
  }

  requestPlatformInfo(
    userSettings: UserSettings,
    callback?: OnSuccessRTCCallback
  ) {
    this.logger.info('Requesting Platform details from native app');
    const platformInfoRequest =
      SoloNativeRTCUtility.buildRTCPlatformDetailsRequest(userSettings);
    this.publishMessage(platformInfoRequest, callback);
  }

  publishStateChangeUpdate(
    sessionState: SessionState,
    stateChangeConfig: CustomizationConfig | ExternalAuthRedirectConfig,
    callback?: OnSuccessRTCCallback
  ) {
    const stateChangeResponse = SoloNativeRTCUtility.buildRTCStateChangeMessage(
      sessionState,
      stateChangeConfig
    );
    this.publishMessage(stateChangeResponse, callback);
  }

  requestStreamSessionContext(
    protocol: SessionProtocols,
    domainName: string,
    allocatedResource: any,
    callback?: OnSuccessRTCCallback
  ) {
    const streamRedirectRequest =
      SoloNativeRTCUtility.buildRTCStreamRedirectMessage(
        protocol,
        domainName,
        allocatedResource
      );
    this.publishMessage(streamRedirectRequest, callback);
  }

  initializeStreaming(
    protocol: SessionProtocols,
    streamInputs: string,
    callback?: OnSuccessRTCCallback
  ) {
    const streamRequest = SoloNativeRTCUtility.buildRTCStreamInitMessage(
      protocol,
      streamInputs
    );
    this.publishMessage(streamRequest, callback);
  }

  subscribeToActionEvent(action: Action, callback: OnSuccessRTCCallback) {
    this.rtcActionToCallbackMap.set(action, callback);
  }

  requestClientDisconnect(callback?: OnSuccessRTCCallback) {
    const disconnectRequest =
      SoloNativeRTCUtility.buildRTCDisconnectRequestMessage();
    this.publishMessage(disconnectRequest, callback);
  }

  requestAuthContext(
    authContextRequestType: AuthContextRequestType,
    authContextInputs: AuthContextInputs,
    callback?: OnSuccessRTCCallback
  ) {
    const authContextRequest =
      SoloNativeRTCUtility.buildRTCAuthContextRequestMessage(
        authContextRequestType,
        authContextInputs
      );
    this.publishMessage(authContextRequest, callback);
  }

  publishSettingsChange(
    settings: UserSettings,
    callback?: OnSuccessRTCCallback
  ) {
    const settingsUpdateRequest =
      SoloNativeRTCUtility.buildRTCSettingsUpdateRequestMessage(settings);
    this.publishMessage(settingsUpdateRequest, callback);
  }

  async requestDnsDomainRecords(
    domain: string,
    recordType: DNSRecordType,
    timeOutInMs?: number
  ) {
    const resolveDNSDomainRequest =
      SoloNativeRTCUtility.buildDnsDomainRecordRequestMessage(
        domain,
        recordType
      );
    return await this.createPromise<ResolveDNSDomainResponse>(
      resolveDNSDomainRequest,
      timeOutInMs
    );
  }

  private async createPromise<T>(
    rtcChannelMessage: RTCChannelMessage,
    timeOutInMs?: number
  ) {
    timeOutInMs = timeOutInMs ?? SoloNativeRTCChannel.SoloCallTimeOutInMs;

    return await new Promise<T>((resolve, reject) => {
      const id = setTimeout(() => {
        reject(new Error('SoloRequestTimeOut'));
      }, timeOutInMs);
      this.publishMessage(rtcChannelMessage, (payload) => {
        clearTimeout(id);
        resolve(payload);
      });
    });
  }

  private publishMessage(
    rtcMessage: RTCChannelMessage,
    callback?: OnSuccessRTCCallback
  ) {
    const rtcMsgString = JSON.stringify(rtcMessage);
    if (!SoloNativeRTCChannel.isChannelAvailable()) {
      this.logger.error(`Unable to publish RTCMessage: ${rtcMsgString}`);
      throw SoloNativeRTCUtility.getChannelUnavailableError();
    }
    this.windowAsAny.webkit.messageHandlers.SoloBrowserCall.postMessage(
      rtcMsgString
    );
    this.rtcActionToCallbackMap.set(rtcMessage.Action, callback);
  }

  private setupListener() {
    this.logger.info('Setting up RTC Channel listener');
    this.windowAsAny.BrowserSoloCall = {};
    this.windowAsAny.BrowserSoloCall.onMessage = (e: string) => {
      this.rtcHandler.handleRTCIncomingMessage(e, this.rtcActionToCallbackMap);
    };
  }

  requestHealthCheckStatus(
    healthCheckRequest: HealthCheckRequest,
    callback?: OnSuccessRTCCallback
  ) {
    const healthCheckStatusRequest =
      SoloNativeRTCUtility.buildHealthCheckStatusRequestMessage(
        healthCheckRequest
      );
    this.publishMessage(healthCheckStatusRequest, callback);
  }

  requestMigrateRegistrationCodes(callback?: OnSuccessRTCCallback) {
    const emptyRequest =
      SoloNativeRTCUtility.buildMigrateRegistrationCodesRequestMessage();
    this.publishMessage(emptyRequest, callback);
  }

  displayWarpDriveUrl(wdUrl: string, callback?: OnSuccessRTCCallback) {
    const wdUrlResult =
      SoloNativeRTCUtility.buildDisplayWDUrlResultMessage(wdUrl);
    this.publishMessage(wdUrlResult, callback);
  }

  brokerAuthenticateResult(
    isAuthenticated: boolean,
    callback?: OnSuccessRTCCallback
  ) {
    const wdUrlResult =
      SoloNativeRTCUtility.buildBrokerAuthenticationResultMessage(
        isAuthenticated
      );
    this.publishMessage(wdUrlResult, callback);
  }
}
