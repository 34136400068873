import {
  CLIENT_PLATFORM,
  COBRANDING_PATH,
  DEFAULT_COBRANDING_ASSETS,
} from './constants';
import {
  IBranding,
  ICobrandingAssetResponse,
  IDeviceType,
  LogUploadEnum,
  ReconnectEnum,
} from './types';
import { ICobrandingAsset } from '@/bridge/types/CobrandingTypes';

import { IHttpClient } from '@bridge/IHttpClient';
import { HttpClientConfig } from '@core/http/HttpClient';
import { ICobranding } from '@bridge/ICobranding';
import { IDevice } from '@/bridge/IDevice';
import { ILogger } from '@/bridge/ILogger';
import { ImageUtils } from '@/helpers/image-utils';
import { IMetrics } from '@bridge/IMetrics';
import { MetricResult, Operation } from '@bridge/types/MetricTypes';
import { IRegion } from '@/bridge/types/RegionTypes';

export abstract class Cobranding implements ICobranding {
  private readonly httpClient: IHttpClient<ICobrandingAssetResponse>;
  private readonly browserPlatformDevice: IDevice;
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;

  constructor(
    client: IHttpClient<ICobrandingAssetResponse>,
    device: IDevice,
    logger: ILogger,
    metrics: IMetrics
  ) {
    this.httpClient = client;
    this.browserPlatformDevice = device;
    this.logger = logger;
    this.metrics = metrics;
  }

  abstract getDeviceType(branding?: IBranding): IDeviceType | undefined;

  async fetchCobrandingAssets(region: IRegion, organizationName: string) {
    const cobrandingPath = `${COBRANDING_PATH}${organizationName}`;
    const httpClientConfig = this.getHttpClientConfig(
      region,
      `${this.browserPlatformDevice.getProductVersion()}`
    );

    return await this.httpClient
      .get(cobrandingPath, httpClientConfig)
      .then(async ({ data }) => {
        this.logger.info(
          `Current workspace customized settings:${JSON.stringify(data)}`
        );
        this.metrics.emit(
          Operation.DescribeClientCustomization,
          MetricResult.Success
        );
        return await this.transformCobrandingAssetData(data);
      })
      .catch(async (error) => {
        // We do not throw error in this case. And return default value after logging the error
        this.logger.error(
          `Branding retrieval for workspace failed with Error:${error}`
        );
        this.metrics.emit(
          Operation.DescribeClientCustomization,
          MetricResult.Fault,
          error
        );
        return await this.transformCobrandingAssetData(undefined);
      });
  }

  private async transformCobrandingAssetData(
    cobrandingAssetResponse?: ICobrandingAssetResponse
  ): Promise<ICobrandingAsset> {
    const { Branding: branding, Configuration: configuration } =
      cobrandingAssetResponse ?? {};
    const cobrandingAsset: ICobrandingAsset = { ...DEFAULT_COBRANDING_ASSETS };
    cobrandingAsset.isReconnectEnabled =
      configuration?.reconnectEnabled === ReconnectEnum.Enabled;

    cobrandingAsset.isLogPublishingEnabled = true;
    if (
      configuration?.logUploadEnabled &&
      configuration?.logUploadEnabled === LogUploadEnum.Disabled
    ) {
      cobrandingAsset.isLogPublishingEnabled = false;
    }

    const deviceType = this.getDeviceType(branding);
    if (!deviceType) return cobrandingAsset;

    const {
      LoginMessage: loginMessage,
      LogoUrl: logoUrl,
      ForgotPasswordLink: forgotPasswordLink,
      SupportEmail: supportEmail,
      SupportLink: supportLink,
    } = deviceType;

    const supportEmailTrim = supportEmail?.trim();
    const supportLinkTrim = supportLink?.trim();
    if (supportEmailTrim || supportLinkTrim) {
      cobrandingAsset.supportUrl =
        (supportEmailTrim && `mailto: ${supportEmailTrim}`) || supportLinkTrim;
    }
    if (loginMessage?.en_US) {
      cobrandingAsset.legalText = loginMessage.en_US;
    }
    if (logoUrl) {
      cobrandingAsset.cobrandingLogo = await ImageUtils.toBase64(
        logoUrl,
        'image/png'
      );
    }
    if (forgotPasswordLink) {
      cobrandingAsset.forgotPasswordUrl = forgotPasswordLink;
    }
    cobrandingAsset.isCobrandingEnabled = true;

    return cobrandingAsset;
  }

  private getHttpClientConfig(
    region: IRegion,
    clientVersion: string
  ): HttpClientConfig {
    return {
      baseURL: region.cobrandingEndpoint,
      params: {
        ClientPlatform: CLIENT_PLATFORM,
        ClientVersion: clientVersion,
      },
    };
  }
}
