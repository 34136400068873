/*
   This class denotes the device object when web client runs in an embedded browser
   on a native client(solo)
*/

import { IDevice } from '@bridge/IDevice';
import { SessionProtocols } from '@bridge/types/SessionTypes';
import {
  AuthCapability,
  PlatformType,
  ProxyType,
} from '@bridge/types/SoloRTCChannelTypes';
import { PlatformSupportInfo } from '@bridge/types/DeviceTypes';
import { MetricConstants } from '@core/constants/MetricConstants';
import { LogLevel } from '@core/constants/LoggerConstants';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';
import { IRegion } from '@bridge/types/RegionTypes';

export class WebViewPlatformDevice implements IDevice {
  private deviceUUID: string | undefined;
  private modelNumber: string | undefined;
  private vendorName: string | undefined;
  private hostOS: PlatformType | undefined;
  private hostOSVersion: string | undefined;
  private protocolVersion: string | undefined;
  private version: string | undefined;
  private authCapabilities: AuthCapability[] | undefined;

  private proxyType: ProxyType = ProxyType.NoProxy;
  private isCanaryRun: boolean = false;
  private isOemHandshaked: boolean = false;
  private supportedProtocols: string[] = [
    SessionProtocols.MAXIBON.toLowerCase(),
  ];

  getAuthCapabilities(): AuthCapability[] {
    return this.authCapabilities ? this.authCapabilities : [];
  }

  getDeviceUUID(): string | undefined {
    return this.deviceUUID;
  }

  getModelNumber(): string | undefined {
    return this.modelNumber;
  }

  getVendorName(): string | undefined {
    return this.vendorName;
  }

  getHostOS(): string | undefined {
    return this.hostOS;
  }

  getHostOSVersion(): string | undefined {
    return this.hostOSVersion;
  }

  getPlatform(): PlatformType | undefined {
    return this.hostOS;
  }

  getPlatformName(): string | undefined {
    return this.hostOS;
  }

  getPlatformAppName(): string {
    return this.isCanaryRun
      ? MetricConstants.NATIVE_PLATFORM_CANARY_APP_NAME
      : MetricConstants.NATIVE_PLATFORM_APP_NAME;
  }

  getPlatformVersion(): string | undefined {
    return this.hostOSVersion;
  }

  getProtocolVersion(): string | undefined {
    return this.protocolVersion;
  }

  getProductVersion(): string | undefined {
    return this.version;
  }

  getProxyType(): string {
    return this.proxyType ?? MetricConstants.UNDEFINED;
  }

  getIsOemHandshaked() {
    return this.isOemHandshaked;
  }

  // This is not supported for EmbeddedBrowser since InSession is handled by Native Client
  getSupportInfo(
    protocols: SessionProtocols[] | undefined
  ): PlatformSupportInfo {
    return {} as any;
  }

  is64BitClient(): boolean {
    return true;
  }

  isCurrentOSSupported(): boolean {
    return true;
  }

  isCurrentPlatformSupported(
    protocols?: SessionProtocols[] | undefined,
    region?: IRegion
  ): boolean {
    if (
      !protocols ||
      !protocols[0] ||
      !this.supportedProtocols ||
      this.supportedProtocols.length === 0
    ) {
      return false;
    }

    const protocolToCompare = protocols[0];
    return this.supportedProtocols.includes(protocolToCompare.toLowerCase());
  }

  isCurrentRegionSupported(region?: IRegion): boolean {
    // Currently solo will be supported in all regions with this commit. But this code cannot be removed since
    // web client will be launched in ADC regions and solo support will come after web ADC is launched. Will keep this
    // code until solo catches up to web in ADC regions
    return region?.isSoloSupported ?? false;
  }

  setAuthCapabilities(authCapabilities?: AuthCapability[]): void {
    this.authCapabilities = authCapabilities;
  }

  setIsOemHandshaked(isOemHandshaked: boolean): void {
    this.isOemHandshaked = isOemHandshaked;
  }

  setSupportedProtocols(supportedProtocols?: SessionProtocols[]): void {
    if (supportedProtocols) {
      const lowercaseProtocols: string[] = [];
      supportedProtocols.forEach((protocol) => {
        if (protocol) {
          lowercaseProtocols.push(protocol.toLowerCase());
        }
      });
      this.supportedProtocols = lowercaseProtocols;
    }
  }

  setDeviceUUID(deviceUUID: string): void {
    this.deviceUUID = deviceUUID;
  }

  setModelNumber(modelNumber: string) {
    this.modelNumber = modelNumber;
  }

  setVendorName(vendorName: string) {
    this.vendorName = vendorName;
  }

  setHostOS(hostOS: PlatformType) {
    this.hostOS = hostOS;
  }

  setHostOSVersion(hostOSVersion: string) {
    this.hostOSVersion = hostOSVersion;
  }

  setPlatformVersion(platformVersion: string) {
    this.version = platformVersion;
  }

  setProtocolVersion(protocolVersion: string) {
    this.protocolVersion = protocolVersion;
  }

  setProxySettings(proxyType: ProxyType) {
    this.proxyType = proxyType;
  }

  setIsCanaryRun(isCanary: boolean) {
    this.isCanaryRun = isCanary;
  }

  saveLog(message: string, logLevel: LogLevel) {
    if (!SoloNativeRTCChannel.publishLogMessage(message, logLevel)) {
      console.error(`Unable to save log to local device Message: ${message}`);
    }
  }

  isNiva(): boolean {
    return false;
  }
}
